import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import classNames from 'classnames';

const Button = ({
    link,
    label,
    text,
    onClick,
    icon,
    type,
    size,
    modifier,
    ariaExpanded,
    ariaControls,
    buttonType,
    showArrow = false,
}) => {
    const Tag = link ? 'a' : 'button';

    let props = {};
    let iconClasses;

    if (Tag === 'button' && buttonType) {
        props.type = buttonType;
    }
    if (link) {
        props.href = link;
    }

    if (onClick) {
        props.onClick = onClick;
    }

    if (label) {
        props['aria-label'] = label;
    }

    if (ariaControls) {
        props['aria-controls'] = ariaControls;
    }

    props['aria-expanded'] = ariaExpanded;

    const classes = classNames(styles['Button'], {
        [styles['Button--Link']]: link,
        [styles[`Button--${type}`]]: type,
        [styles[`Button--${modifier}`]]: !!modifier,
        [styles[`Button--${size}`]]: size,
        [styles[`Button--Icon`]]: icon,
        [styles[`Button--WithArrow`]]: showArrow,
    });

    iconClasses = classNames(styles['Button__Icon']);

    return (
        <Tag {...props} className={classes}>
            <span className={styles['Button__Text']}>
                {text}
                {showArrow && !icon && (
                    <img
                        src={'/assets/svg/arrow--right-alternative.svg'}
                        alt="Signalisten Logotyp"
                        className={styles['Button--Arrow']}
                    />
                )}
            </span>
            {icon && <img src={icon} alt="" className={iconClasses} />}
        </Tag>
    );
};

Button.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
};

Button.defaultProps = {
    link: '',
    label: '',
    text: '',
    onClick: () => {},
    icon: '',
    type: '',
};

export default Button;
