import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import isEmpty from '../../utils/isEmpty';
import i18n from '../../i18n';
import Wysiwyg from '../Wysiwyg';

const Footer = ({
    contact,
    address,
    info,
    social,
    copyrightYear,
    office,
    showIfCookieScriptisNotActivated,
}) => {
    
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Menu']}>
                <div className={styles['Footer__MenuInner']}>
                    <div className={styles['Footer__MenuSection']}>
                        <div className={styles['Footer__LogoContainer']}>
                            <a href="/" className={styles['Footer__Logo']}>
                                <img
                                    src={'/assets/svg/logo-white-line.svg'}
                                    alt="Signalisten Logotyp"
                                    className={styles['Footer__LogoImage']}
                                />
                            </a>
                        </div>
                        {!isEmpty(info) && <OfficeNav {...office} />}
                        {!isEmpty(contact) && <ContactNav {...contact} />}
                        {!isEmpty(social) && <SocialNav {...social} />}
                    </div>
                </div>
                <div className={styles['Footer__MenuBottom']}>
                    <div className={styles['Footer__Copyright']}>
                        {i18n.t('footer.copyright', copyrightYear)}
                    </div>
                    {!isEmpty(info.items) &&
                        info.items !== undefined &&
                        info.items.map(({ href, title }, idx) => {
                            return (
                                <a
                                    className={styles['Footer__InfoLink']}
                                    key={idx}
                                    href={href}
                                >
                                    {title}
                                </a>
                            );
                        })}
                    {!showIfCookieScriptisNotActivated && (
                        <a
                            className={styles['Footer__InfoLink']}
                            id="csconsentlink"
                            href="#"
                        >
                            {i18n.t('footer.cookies')}
                        </a>
                    )}
                </div>
            </div>
        </footer>
    );
};

const ContactNav = ({ title, text }) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__ContactNav']}
            aria-labelledby="footer-contact"
        >
            <h3 className={styles['Footer__ContactTitle']} id="footer-contact">
                {title}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </nav>
    );
};

const AddressNav = ({ title, text }) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__AddressNav']}
            aria-labelledby="footer-address"
        >
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </nav>
    );
};

const OfficeNav = ({
    title,
    text,
    items,
    showIfCookieScriptisNotActivated,
}) => {
    return (
        <nav
            role="navigation"
            className={styles['Footer__OfficeNav']}
            aria-labelledby="footer-info"
        >
            <h3 className={styles['Footer__ContactTitle']} id="footer-info">
                {title}
            </h3>
            <Wysiwyg html={text} modifier={'Footer'} />
        </nav>
    );
};

const SocialNav = ({ facebookUrl, linkedinUrl, instagramUrl }) => {
    return (
        <nav
            role="navigation"
            aria-labelledby="social-nav"
            className={styles['Footer__SocialNav']}
        >
            <h3 className="sr-only" id="social-nav">
                {i18n.t('footer.socialLabel')}
            </h3>
            <h3 className={styles['Footer__ContactTitle']} id="social-nav">
                {i18n.t('footer.followUs')}
            </h3>
            <div className={styles['Footer__SocialNav__Links']}>
                {linkedinUrl && (
                    <a
                        href={linkedinUrl}
                        className={
                            styles['Footer__SocialNav__Link'] +
                            ' ' +
                            styles['Footer__SocialNav__Link--Linkedin']
                        }
                    >
                        <span className="sr-only">Linkedin</span>
                    </a>
                )}
                {facebookUrl && (
                    <a
                        href={facebookUrl}
                        className={
                            styles['Footer__SocialNav__Link'] +
                            ' ' +
                            styles['Footer__SocialNav__Link--Facebook']
                        }
                    >
                        <span className="sr-only">Facebook</span>
                    </a>
                )}
                {instagramUrl && (
                    <a
                        href={instagramUrl}
                        className={
                            styles['Footer__SocialNav__Link'] +
                            ' ' +
                            styles['Footer__SocialNav__Link--Instagram']
                        }
                    >
                        <span className="sr-only">Instagram</span>
                    </a>
                )}
            </div>
        </nav>
    );
};

Footer.defaultProps = {
    contact: {},
    address: {},
    info: {},
    social: {},
    copyrightYear: 0,
    showIfCookieScriptisNotActivated: false,
};

Footer.propTypes = {
    contact: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    address: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    info: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.array,
    }),
    social: PropTypes.shape({
        facebookUrl: PropTypes.string,
        instagramUrl: PropTypes.string,
        youtubeUrl: PropTypes.string,
    }),
    showIfCookieScriptisNotActivated: PropTypes.bool,
    copyrightYear: PropTypes.number,
};

ContactNav.defaultProps = {
    title: '',
    text: '',
};
ContactNav.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

AddressNav.defaultProps = {
    title: '',
    text: '',
};

AddressNav.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

OfficeNav.defaultProps = {
    title: '',
    items: [],
    showIfCookieScriptisNotActivated: false,
};

OfficeNav.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    showIfCookieScriptisNotActivated: PropTypes.bool,
};

SocialNav.defaultProps = {
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
};

SocialNav.propTypes = {
    facebookUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
};

export default Footer;
