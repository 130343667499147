import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import NavigationLocal from '../NavigationLocal';
import SearchModal from '../SearchModal';
import isEmpty from '../../utils/isEmpty';
import AnimateHeight from 'react-animate-height';
import i18n from '../../i18n';

import styles from './Navigation.module.scss';

const Navigation = ({ menu, search, myPage, searchModal }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [expandedMenuIndex, setExpandedMenuIndex] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const itemContainerRef = useRef(null);
    const megaMenuRef = useRef(null);
    const toggleSearchModal = () => setExpanded(!expanded);
    const closeSearchModal = () => setExpanded(false);

    const handleToggleMenu = (index) => {
        setExpandedMenuIndex(expandedMenuIndex === index ? null : index);
    };

    const handleClickOutside = (event) => {
        if (
            megaMenuRef.current &&
            !megaMenuRef.current.contains(event.target)
        ) {
            setExpandedMenuIndex(null); // Close the MegaMenu
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <nav className={styles['Navigation']} role="navigation">
                <div className={styles['Navigation__Container']}>
                    <div className={styles['Navigation__MenuWrap']}>
                        <div className={styles['Navigation__Header']}>
                            <a
                                href="/"
                                className={styles['Navigation__Logotype']}>
                                <img
                                    src={
                                        '/assets/svg/logo-signalisten--small.svg'
                                    }
                                    alt="Signalisten Logotyp"
                                    className="Hero__LogoIcon"
                                />
                            </a>
                        </div>
                        <div className={styles['Navigation__Menu']}>
                            <div
                                className={styles['Navigation__TopMenu']}
                                aria-expanded={isVisible}
                                aria-controls="dropdown-menu">
                                <div
                                    className={
                                        styles['Navigation__TopMenuInner']
                                    }>
                                    {menu.map(
                                        (
                                            { href, title, items, isActive },
                                            idx
                                        ) => {
                                            return (
                                                <ToggleItem
                                                    key={idx}
                                                    href={href}
                                                    title={title}
                                                    items={items}
                                                    isActive={isActive}
                                                    isMegaMenuExpanded={
                                                        expandedMenuIndex ===
                                                        idx
                                                    }
                                                    onToggle={() =>
                                                        handleToggleMenu(idx)
                                                    }
                                                    megaMenuRef={
                                                        expandedMenuIndex ===
                                                        idx
                                                            ? megaMenuRef
                                                            : null
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                            <div className={styles['Navigation__Buttons']}>
                                <div className={styles['Navigation__MyPage']}>
                                    {myPage.href && (
                                        <Button
                                            text={i18n.t('navigation.myPage')}
                                            link={myPage.href}
                                            type="Primary"
                                            modifier={'MyPages'}
                                            icon={
                                                '/assets/svg/profile--new.svg'
                                            }
                                        />
                                    )}
                                </div>
                                <div className={styles['Navigation__Search']}>
                                    <Button
                                        onClick={toggleSearchModal}
                                        aria-expanded={expanded}
                                        modifier={'SearchButton'}
                                        type="Primary"
                                        aria-controls={'NavigationSearch'}
                                        text={i18n.t('search.search')}
                                        icon={'/assets/svg/search--new.svg'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <AnimateHeight duration={350} height={expanded ? 'auto' : 0}>
                <div
                    id={'NavigationSearch'}
                    className={styles['Navigation__SearchModal']}
                    role="region"
                    tabIndex="-1"
                    ref={itemContainerRef}>
                    <SearchModal
                        closeSearchModal={closeSearchModal}
                        isExpanded={expanded}
                        isClosable={true}
                        {...searchModal}
                    />
                </div>
            </AnimateHeight>
        </>
    );
};

const ToggleItem = ({
    title,
    href,
    items,
    isMegaMenuExpanded,
    onToggle,
    megaMenuRef,
    isActive,
}) => {
    const navItemClasses = classNames(styles['Navigation__ToggleItem'], {
        [styles['Navigation__ToggleItem--Expanded']]: isMegaMenuExpanded,
        [styles['Navigation__ToggleItem--Active']]: isActive,
    });

    const megaMenuContentClasses = classNames(styles['Navigation__MegaMenu']);

    return (
        <div
            className={styles['Navigation__ToggleItemWrapper']}
            ref={megaMenuRef}>
            <button className={navItemClasses} onClick={onToggle}>
                <span className={styles['Navigation__ToggleItemTitle']}>
                    {title}
                </span>
                <div className={styles['Navigation__ToggleItemIcon']}>
                    <img
                        src={'/assets/svg/arrow--down.svg'}
                        alt="Signalisten Logotyp"
                        className="Hero__LogoIcon"
                    />
                </div>
            </button>
            {isMegaMenuExpanded && (
                <div className={megaMenuContentClasses}>
                    <div className={styles['Navigation__MegaMenuContainer']}>
                        <a
                            href={href}
                            className={styles['Navigation__MegaMenuMainLink']}>
                            <span
                                className={
                                    styles['Navigation__MegaMenuMainLinkTitle']
                                }>
                                {title}
                            </span>
                            <img
                                src={'/assets/svg/arrow--right-alternative.svg'}
                                alt={title}
                                className={
                                    styles['Navigation__MegaMenuMainLinkIcon']
                                }
                            />
                        </a>
                        <ul className={styles['Navigation__MegaMenuLinks']}>
                            {items.map((item, idx) => {
                                return (
                                    <li
                                        key={idx}
                                        className={
                                            styles['Navigation__MegaMenuItem']
                                        }>
                                        <a
                                            href={item.href}
                                            className={
                                                styles[
                                                    'Navigation__MegaMenuItemLink'
                                                ]
                                            }>
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

Navigation.propTypes = {
    menu: PropTypes.array,
    localMenu: PropTypes.array,
    contact: PropTypes.object,
    myPage: PropTypes.object,
    search: PropTypes.object,
    searchModal: PropTypes.object,
};

Navigation.defaultProps = {
    menu: [],
    localMenu: [],
    contact: {},
    myPage: {},
    search: {},
    searchModal: {},
};

export default Navigation;
