import React from 'react';
import PropTypes from 'prop-types';
import styles from './Snackbar.module.scss';
import Button from '../Button';

const Snackbar = ({ text, buttonText, onClick }) => {
    return (
        <div className={styles.Snackbar}>
            <div className={styles.Snackbar__Wrap}>
                <p className={styles.Snackbar__Text}>{text}</p>
                <div className={styles.Snackbar__Button}>
                    <Button
                        text={buttonText}
                        type="Primary"
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    );
};

Snackbar.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
};

export default Snackbar;
