import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchModal.module.scss';
import Button from '../Button';
import classNames from 'classnames';
import i18n from '../../i18n';

const SearchModal = ({
    searchPageUrl,
    isClosable,
    isExpanded,
    modifier,
    inputId,
    buttonColor = 'white',
    closeSearchModal,
}) => {
    const [searchInput, setSearchInput] = useState('');
    const inputRef = useRef();

    inputId = inputId || 'search';

    useEffect(() => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isExpanded]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            const ENTER = 13;
            const currentElement = document.activeElement;
            const keyCode = e.keyCode;
            const isSearch =
                currentElement.id === 'search' ||
                currentElement.id === 'search-modal-input' ||
                currentElement.id === 'searchpage_id';

            if (isSearch && keyCode === ENTER && searchInput) {
                window.location.href = `${searchPageUrl}?q=${encodeURIComponent(
                    searchInput
                )}`;
            }
        };
        document.addEventListener('keyup', handleKeyPress);

        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [searchInput, searchPageUrl]);

    const classes = classNames(styles['SearchModal'], {
        [styles[`SearchModal--${modifier}`]]: !!modifier,
    });

    return (
        <div className={classes}>
            <div className={styles['SearchModal__Container']}>
                <div className={styles['SearchModal__Search']}>
                    <label htmlFor={inputId} className="sr-only">
                        {i18n.t('searchModal.label')}
                    </label>
                    <input
                        className={styles['SearchModal__Input']}
                        ref={inputRef}
                        placeholder={i18n.t('searchModal.placeholder')}
                        id={inputId}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>
                <div className={styles['SearchModal__SearchButton']}>
                    <Button
                        icon={`/assets/svg/search--black.svg`}
                        modifier={'SearchModalSearch'}
                        label={i18n.t('searchModal.label')}
                        onClick={() => {
                            window.location.href = `${searchPageUrl}?q=${encodeURIComponent(
                                searchInput
                            )}`;
                        }}
                    />
                </div>
                {isClosable && (
                    <div className={styles['SearchModal__ExitButton']}>
                        <Button
                            icon={'/assets/svg/icon--exit.svg'}
                            modifier={'SearchModalClose'}
                            label={i18n.t('searchModal.close')}
                            onClick={closeSearchModal}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

SearchModal.propTypes = {
    searchPageUrl: PropTypes.string,
    searchButton: PropTypes.object,
    exitButton: PropTypes.object,
};

SearchModal.defaultProps = {
    searchPageUrl: '',
    searchButton: {},
    exitButton: {},
};

export default SearchModal;
